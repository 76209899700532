import React from "react";
import { Button, Row, Col, Table, Icon, Tooltip, Spin, Popover, message } from "antd";

import queryString from "query-string"

import Api from "../components/api";
import Console from "../components/console";
import Seo from "../components/seo";

const ButtonGroup = Button.Group;

class Accounts extends React.Component {

    state = {
        user: null,
        data: null,
        transactions: [],
        id: null
    }

    componentDidMount() {
        this.load();
    }

    load = () => {
        this.setState({user: null, data: null, id: null, transactions: []});
        const {id} = queryString.parse(this.props.location.search);
        this.setState({id});
        this.loadTransaction();
        Api("account_details", {account_id: id})
                .then(user => {
                    this.setState({user});
                });
    }

    loadTransaction = () => {
        this.setState({loading: true});
        const {id} = queryString.parse(this.props.location.search);
        let {transactions} = this.state;
        Api("account_transactions", {account_id: id, offset: transactions.length})
                .then(data => {
                    let {transactions} = this.state;
                    if (data.transactions) {
                        transactions = transactions.concat(data.transactions);
                    }
                    this.setState({transactions, data});
                }).finally(() => {
                    this.setState({loading: false});
                });
    }

    applyTransaction = (transaction_id, boolean_decision) => {
        Api("transaction_validation", {transaction_id, boolean_decision})
                .then(response => {
                    if (response.result) {
                        message.success(boolean_decision ? 'La transaction a été validée' : 'La transaction a été annulée');
                        this.load();
                    } else {
                        message.error(response.message);
                    }
                });
    }

    render() {
        const {user, data, id, transactions, loading} = this.state;

        const columns = [
            {
                title: 'N° transaction',
                dataIndex: 'id',
                key: 'id'
            },
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
                render: (text) => <React.Fragment>{(new Date(text)).toLocaleString("fr-FR")}</React.Fragment>
            },
            {
                title: 'Compte source',
                dataIndex: 'source',
                key: 'source',
                render: (text, record) => <React.Fragment>{text}{record.source_user && " (" + record.source_user + ")"}</React.Fragment>
            },
            {
                title: 'Compte destination',
                dataIndex: 'destination',
                key: 'destination',
                render: (text, record) => <React.Fragment>{text}{record.destination_user && " (" + record.destination_user + ")"}</React.Fragment>
            },
            {
                title: 'Montant',
                dataIndex: 'amount',
                key: 'amount',
                render: (text) => <React.Fragment>{text.toLocaleString("fr", {minimumFractionDigits: 2})} €</React.Fragment>
            },
            {
                title: 'Auteur',
                dataIndex: 'author',
                key: 'author'
            },
            {
                title: '',
                dataIndex: 'comment',
                key: 'comment',
                render: (text) => text && <Tooltip placement="left" title={text}>
                <Icon type="message" />
            </Tooltip>
            },
            {
                title: '',
                dataIndex: 'state',
                key: 'state',
                render: (text, record) => text === "WAITING_APPROVE" ? <Tooltip placement="left" title="En attente de validation"><Icon type="issues-close" style={{ color: '#CC0000' }} /></Tooltip> :
                            text === "TO_APPROVE" ? <Popover placement="left" content={ < ButtonGroup >
                                                        <Button onClick={() => this.applyTransaction(record.id, true)}>Appliquer</Button>
                                                        <Button onClick={() => this.applyTransaction(record.id, false)}>Annuler</Button>
                                                        </ButtonGroup>}><Icon type="question-circle" style={{ color: '#CC0000' }} /></Popover> : <Icon type="check-circle" />
            }
        ];

        return <Console menu="accounts" help={["account_details", "account_transactions", "transaction_validation", "transaction_validation_notification"]} onChangeUser={this.load}>
            <Seo title="Console" />
            <h1>Compte n°{id}</h1>
            <div className="account_details">
                { user && user.advisor ?
                            <div>
                                <Row gutter={30}>
                                    <Col span={5}><b>Détenteur :</b></Col>
                                    <Col span={5}>{user.owner}</Col>
                                    <Col span={4}></Col>
                                    <Col span={5}><b>Taux du compte :</b></Col>
                                    <Col span={5}>{user.rate} %</Col>
                                </Row>
                                <Row gutter={30}>
                                    <Col span={5}><b>Gestionnaire :</b></Col>
                                    <Col span={5}>{user.advisor}</Col>
                                    <Col span={4}></Col>
                                    <Col span={5}><b>Intérêts :</b></Col>
                                    <Col span={5}>{user.interest.toLocaleString("fr", {minimumFractionDigits: 2})} €</Col>
                                </Row>
                                <Row gutter={30}>
                                    <Col span={5}></Col>
                                    <Col span={5}></Col>
                                    <Col span={4}></Col>
                                    <Col span={5}><b>Solde :</b></Col>
                                    <Col span={5}>{user.amount.toLocaleString("fr", {minimumFractionDigits: 2})} €</Col>
                                </Row>
                            </div> : <Spin />
                }
            </div>
            <Table dataSource={transactions} loading={!data} columns={columns} />
            <Button loading={loading} onClick={this.loadTransaction} disabled={!data || data.total <= transactions.length} style={{float: "right"}}>Charger plus de résultats { data && data.total > 0 && <React.Fragment>({Math.floor(transactions.length/data.total * 100)}%)</React.Fragment>}</Button>
            <div style={{ clear: 'both', marginBottom: "30px" }}></div>
        </Console>;
    }
}
;

export default Accounts;
